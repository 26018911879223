import Vue from 'vue'
import Router from "vue-router";

Vue.use(Router);

const routes = [
  {
    path: '/',
    component: () => import('../components/Default.vue'),
    children: [
      {
        path: '/',
        name: 'home',
        component: () => import('../pages/Home.vue'),
        meta: {
          title: 'Home',
        }
      },
      {
        path: '/work',
        name: 'work',
        component: () => import('../pages/Work.vue'),
        meta: {
          title: 'Work',
        }
      },
      {
        path: '/news',
        name: 'news',
        component: () => import('../pages/News.vue'),
        meta: {
          title: 'News',
        }
      },
      {
        path: '/news/:url',
        name: 'news-details',
        component: () => import('../pages/NewsDetails.vue'),
        meta: {
          title: 'News'
        },
      },
      {
        path: '/contact',
        name: 'contact',
        component: () => import('../pages/Contact.vue'),
        meta: {
          title: 'Contact',
        }
      },
      {
        path: '/Legacy',
        name: 'Legacy',
        component: () => import('../pages/Legacy.vue'),
        meta: {
          title: 'Legacy',
        }
      },
      {
        path: '/casestudy/bullybeegone',
        name: 'bullybeegone',
        component: () => import('../pages/case-study/bullybeegone.vue'),
        meta: {
          title: 'Bullybeegone',
        }
      },
      {
        path: '/casestudy/sittinonmusic',
        name: 'sittinonmusic',
        component: () => import('../pages/case-study/sittinonmusic.vue'),
        meta: {
          title: 'Sittinonmusic',
        }
      },
      {
        path: '/casestudy/springboard',
        name: 'springboard',
        component: () => import('../pages/case-study/springboard.vue'),
        meta: {
          title: 'Springboard',
        }
      },
      {
        path: '/casestudy/idaay',
        name: 'idaay',
        component: () => import('../pages/case-study/idaay.vue'),
        meta: {
          title: 'IDAAY',
        }
      },
      {
        path: '/casestudy/munilogic',
        name: 'munilogic',
        component: () => import('../pages/case-study/munilogic.vue'),
        meta: {
          title: 'Munilogic',
        }
      },
      {
        path: '/casestudy/touchnewjersey',
        name: 'touchnewjersey',
        component: () => import('../pages/case-study/touchnewjersey.vue'),
        meta: {
          title: 'Touchnewjersey',
        }
      },
      {
        path: '/casestudy/sarchyeleacock',
        name: 'sarchyeleacock',
        component: () => import('../pages/case-study/sarchyeleacock.vue'),
        meta: {
          title: 'Sarchyeleacock',
        }
      },
      {
        path: '/Support',
        name: 'support',
        component: () => import('../pages/Support.vue'),
        meta: {
          title: 'Support',
        }
      },
    ]
  },
  {
    path: '/billionacts',
    name: 'billionacts',
    component: () => import('../pages/case-study/Billionacts'),
    meta: {
      title: 'Billion acts',
    }
  },
  {
    path: '/advisorbob',
    name: 'advisorbob',
    component: () => import('../pages/case-study/Advisorbob'),
    meta: {
      title: 'AdvisorBOB',
    }
  },
  {
    path: '/littleredfashion',
    name: 'littleredfashion',
    component: () => import('../pages/case-study/littleredfashion'),
    meta: {
      title: 'Littleredfashion',
    }
  }
];

const router = new Router({
  routes,
  base: '/',
  mode: 'history',
});

router.beforeEach((to, from, next) => {
  if(to.meta.title)
    document.title = to.meta.title ? 'Ocean Ring Technologies - ' + to.meta.title : 'Ocean Ring Technologies';
  next();
});

export default router
