<template>
  <div id="app">
    <router-view />
    <div id="preloader" class="" style="overflow:hidden; z-index:999999; "></div>
    <div class="loader" :class="{'hide-loader': showAnimation}">
      <div class="loader-wrapper" :class="{'hide-div': showAnimation}">
        <img src="./assets/img/wave-1.png" class="wave-bottom" alt="">
        <img src="./assets/img/wave-2.png" class="wave-top" alt="">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'App',
  data() {
      return {
          showAnimation: false
      }
  },
  mounted() {
      setTimeout(() => {
          document.getElementById("preloader").style.display = "none";
          this.showAnimation = true
      }, 1000);
  },

}
</script>

<style lang="scss">
  .loader {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 9;

    &.hide-loader {
      visibility: hidden;
      /*opacity: 0;*/
      transition: 1s;
    }

    .loader-wrapper {
      width: 100%;
      height: 100%;
      overflow: hidden;
      position: relative;

      img {
        height: 100%;
        position: absolute;
        width: 100%;
        transition: 1.8s;
        transition-delay: 0.1s;

        &.wave-bottom {
          bottom: 0;
        }

        &.wave-top {
          top: 0;
        }
      }

      &.hide-div {
        img.wave-bottom {
          bottom: -100%;
        }

        img.wave-top {
          top: -100%;
        }
      }
    }
  }
  @media (max-width: 1000px) {
      .loader {
          .loader-wrapper {
              img {
                  width: 1000px;
              }
          }
      }
  }
</style>
