import Vue from 'vue';
import App from './App.vue';
import router from './router';
import AOS from 'aos';
import 'aos/dist/aos.css';
import axios from "axios";
import VueAxios from "vue-axios";
import VueCompositionAPI from '@vue/composition-api';
import VueMoment from 'vue-moment';


const http = axios.create({
  baseURL: 'https://oceanring.net/oceanringtechnologies/'
});

window.axios = require('axios');
Vue.use(VueAxios, http, axios);
Vue.use(VueCompositionAPI);
Vue.use(VueMoment);

Vue.config.productionTip = false;


new Vue({
  created () {
    AOS.init()
  },
  router,
  render: h => h(App),
}).$mount('#app');
